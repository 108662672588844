.cadastroNovoscliente-card-title {
    background-color: #2a3072;
    border-bottom: 1px solid black;
    border-radius: 8px 8px 0px 0px;
    
}
.cadastroNovoscliente-card-title h4 {
    margin: 1%;
    color: white;
}
.icon-text {
    display: flex;
    align-items: center;
    padding:1%;
}
.bold {
    font-weight: bold;
}

.botao-buscar-todos {
    margin-left: 35px !important;
}