

.content {
    flex: 1; /* Permite que a seção ocupe o espaço restante */
    overflow-y: auto; /* Adiciona rolagem interna apenas se necessário */
  }
  
  body, html {
    height: 100%; /* Garante que o body e html também ocupem a tela */
    margin: 0; /* Remove margens padrão */
    /* overflow: hidden;  Evita rolagem global */
  }
    #root {
      height: 100%; /* Se você estiver usando React com ReactDOM.render */
      display: flex;
      flex-direction: column;
      background-color: #fff;
      
    }
    
    .dashboard-graficos {
      flex: 1; /* Faz com que o conteúdo do Row ocupe o máximo da altura disponível */
      background-color: #f8f8f8; /* Certifica que o fundo azul permanece */
    }
    
    .page-container {
      height: 100vh; /* Altura total da tela */
      display: flex;
      flex-direction: column; /* Organiza os elementos em coluna */
      justify-content: space-between; /* Distribui o espaço verticalmente */
      overflow: hidden; /* Impede a rolagem */
    }
  
    
    /* Barra Superior */
    .header {
      flex-shrink: 0;
      padding-bottom: 20px;
    }
    
    /* Conteúdo principal */
    .content {
      flex: 1; /* Permite que o conteúdo principal ocupe o espaço restante */
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    
    /* Botões na parte inferior */
    .actions {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      gap: 10px;
      padding: 10px;
    }
    
    .logo {
      margin-top: 60px;
      height: 40px;
    }
    
    .user-info {
      text-align: right;
      color: #D1D5FF; /* Texto em tom mais claro */
    }
    
  .caixa-form {
    margin: 0 auto;
    margin-top: 30px;
    /* Centralizar horizontalmente o formulário */
  }
  .input { 
    height: 50px !important;
    font-size: 22px !important;
    border-radius: 8px !important;
    padding-left: 20px !important;
    max-width: 1200px;
    
    margin: 0 auto;
  }
  
  .botao-pontuar{
    width: 100%;
    max-width: 1200px !important;
    
    margin: 0 auto !important;
  }
    
    .nfc-section {
      width: 49%;
      background-color: #F8F8F8; /* Fundo da seção NFC */
      padding: 15px;
      padding-right: 50px;
      padding-left: 50px;
      border-radius: 8px;
    
      box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.1) !important;
    }
    
    .tabs {
      display: flex;
      margin-bottom: 10px;
    }
    
    .tab {
      padding: 10px 20px;
      border: 1px solid #ffffff;
      background-color: #f7f7f7;
      color: #ffffff;
      cursor: pointer;
      border-radius: 5px 5px 0 0;
    }
    
    .tab.active {
      background-color: #30367E;
      font-weight: bold;
    }
    
    .form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    
    .form-group {
      display: flex;
      flex-direction: column;
    }
    
    .form-group label {
      color: #D1D5FF;
      margin-bottom: 5px;
    }
    
    .form-group input {
      padding: 8px;
      border: 1px solid #ffffff;
      background-color: #252A64;
      color: #ffffff;
      border-radius: 4px;
    }
    
    .form-group button {
      padding: 8px;
      background-color: #4C52CC; /* Botão em tom intermediário */
      color: #ffffff;
      border: none;
      cursor: pointer;
      border-radius: 4px;
    }
    
    .form-group button:hover {
      background-color: #6166E0; /* Hover do botão */
    }
    
    /* Caixa Livre */
    .caixa {
      width: 49%;
      text-align: center;
      background-color: #fff; /* Fundo da área */
      padding: 20px;
      border-radius: 8px;
      color: #3f3f3f;
      font-size: 24px;
      font-weight: bold;
    }
    
    .total {
      margin-top: 10px;
      font-size: 18px;
      color: #D1D5FF;
    }
    
    /* Botões de Ação */
    .actions {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }
    
    .action-btn {
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      color: #ffffff;
      cursor: pointer;
      border-radius: 8px;
      transition: background-color 0.3s ease;
    }
    
    .action-btn.red {
      background-color: #E74C3C;
    }
    
    
    .action-btn.orange {
      background-color: #f25504;
    }
    
    .action-btn.red:hover {
      background-color: #C0392B;
    }
    
    .action-btn.yellow {
      background-color: #d4ad0d;
      color: #fff; /* Contraste com o fundo */
    }
    
    .action-btn.yellow:hover {
      background-color: #bb980b;
    }
    
    .action-btn.green {
      background-color: #27AE60;
    }
    
    .action-btn.green:hover {
      background-color: #1e864a;
    }
  
    
    .action-btn.blue {
      background-color: #2A3072;
    }
    
    .action-btn.blue:hover {
      background-color: #2A3072;
    }
    
    
  
    .action-btn.vinho {
      background-color: #9e3400;
    }
    
    .action-btn.vinho:hover {
      background-color: #9e3400;
    }
    
    .logo-img{
      padding: 0 !important;
      margin: 0;
      width: 260px
    }
  
   
    .logo-img-topo{
      padding: 10px !important;
      margin: 5;
      width: 80px;
    }
    
    .caixa-container-logo{
      text-align: center;
      margin-bottom: 0px !important;
      margin-top: 80px !important;
    }
  
    .imagem-logo{
      width: 50px !important
    }
  
    .swal-text {
      text-align: center;
    }
    
  .botao-cancelar{
    margin-left: 10px;
  }
    
    .invoice{
      padding: 0;
      font-family: "Avenir", serif;
      font-weight: 100;
      width: 100%;
      max-width: 1000px;
      margin: 2% auto;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 5px;
      background: #fff;
      color: #3f3f3f
    }
    .header{
      display: flex;
      width: 100%;
      border-bottom: 2px solid #eee;
      align-items: center;
    }
    .header--invoice{
      order: 2;
      text-align: right;
      width: 40%;
      margin: 0;
      padding: 0;
    }
    .invoice--date,
    .invoice--number{
      font-size: 12px;
      color: #3f3f3f;
    }
    .invoice--recipient{
      margin-top: 25px;
      margin-bottom: 4px;
    }
    .header--logo{
      order: 1;
      font-size: 32px;
      width: 60%;
      font-weight: 900;
      text-align: left;
    }
    .logo--address{
      font-size: 12px;
      padding: 4px;
    }
    .description{
      margin: auto;
      text-align: justify;
    }
    .items--table{
      width: 100%;
      padding: 0px;
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.8em;
      font-family: sans-serif;
      min-width: 400px;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 8px !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-decoration: none;
      transition: transform 0.2s, box-shadow 0.2s;
      thead{
        background: #2A3072;
        color: #fff;
        text-align: center;
        font-weight: 800;
      }
      tbody{
        text-align: center;
      }
      .total-price{
        border: 2px solid #444;
        padding-top: 4px;
        font-weight: 800;
        background: lighten(green, 50%);
      }
  
      .items--table tbody tr {
        border-bottom: 1px solid #dddddd;
    }
    
    .items--table tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }
    
    .items--table tbody tr:last-of-type {
        border-bottom: 2px solid #009879;
    }
    
    }
  
    tr {
      margin-bottom: 1em;
      padding: 30px;
      @media (min-width: 86mm) {
        
        display: table-row;
        border-width: 1px;
      }
      
      &:last-of-type {
        margin-bottom: 0; 
      }
      
      &:nth-of-type(even) {
        @media (min-width: 86mm) {
          background-color: rgba(0,0,0,.12);
        }
      }
    }
  
    .texto-nao-computado{
      text-align: center;
    }
    
    .icone-grafico-caixa {   
      color: #2A3072 !important;
      margin-bottom: 8px;
      width: 200px !important;
      margin: auto;
      font-size: 130px;
  }
  
  
  
  
  #custom-swal-image {
      max-width: 100%; /* Garante que a imagem não ultrapasse o modal */
      height: auto; /* Mantém a proporção da imagem */
      border-radius: 10px; /* Adiciona bordas arredondadas */
      margin: 10px 0; /* Espaçamento ao redor da imagem */
  }
  
  #custom-swal-popup {
    max-width: 800px !important; /* Ajuste a largura como desejar */
    width: 90%; /* Pode usar porcentagem para responsividade */
  }