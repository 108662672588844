@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* { 
    margin: 0; 
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    
 }
 body {
    background-color: #30367e
 }