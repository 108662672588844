@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700&display=swap');
body {
  font-family: 'Nunito', sans-serif !important;
}
h1, h2, h3, p {
  font-family: 'Nunito', sans-serif !important;
}
.nanito {
  font-family: 'Nunito', sans-serif !important;
}


.container-pc-cliente{
    background-color: #f8f8f8 !important;
    min-height: 100vh !important;
    border-radius: 3%;
}
.container-pc-cliente > .areadocliente{
  display: flex;
  flex-wrap:wrap;
}
.indiqueumAmigo {
  padding: 5%;
}
.card-extrato {
    flex-grow: 1;
    width: 48%;
    margin: 1%;
    background-color: transparent !important;
    color: #888 !important; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.card {
  border: none !important;
}
.sem-scroll {
  overflow: hidden;
  overflow-x: hidden;
}

.indiqueUmAmigo-img {
  width: 80%;
  bottom: 0;   
}
.card-indiqueumamigo {
  background-color: #30367E;
  padding: 5%;
  color: white;
  border-radius: 7%;
}
@media screen and (max-width: 600px) {
    .container-pc-cliente {
      flex-wrap: nowrap !important;
    }
  }
 .satisfacao-img {
  width: 30%;
  position: absolute;
  bottom: -5%;
  right: 0;
  z-index: 0;
  opacity: 0.5;
 }

 .card-como-funciona {
  flex-grow: 1;
  width: 48%;
  margin: 1;
  background-color: #fff !important;
    color: #888 !important; 
    -webkit-box-shadow:  0 2px 4px  rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow:  0 2px 4px  rgba(0, 0, 0, 0.1) !important;
    box-shadow:  0 2px 4px  rgba(0, 0, 0, 0.1) !important;
    padding: 20px;
    height: 190px !important;
    margin-top: 37px;
    
 }

 
 .card-table {
  flex-grow: 1;
  width: 48%;
  margin: 1;
  background-color: #fff !important;
    color: #888 !important; 
    -webkit-box-shadow:  0 2px 4px  rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow:  0 2px 4px  rgba(0, 0, 0, 0.1) !important;
    box-shadow:  0 2px 4px  rgba(0, 0, 0, 0.1) !important;
    padding: 20px;
    
 }
 .iconsminds-shopping-bag {
  font-size: 80px;
  color: #30367E;
 }
 

 .page-link{
  color: #30367E;
 }

 .page-item {
  --bs-pagination-color: #30367E !important;
  --bs-pagination-active-bg: #30367E !important;
  --bs-pagination-active-color: #fff !important;
 }