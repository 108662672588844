.configuracao-usuario-button-novo{
    text-align: end;
}
.configuracao-usuario-novo-div-form{
    padding-left:25%;
    padding-right:25%;
}

.form-empresa-input{
    width: 50% !important;
    margin-right: 20%;
}

.react-datepicker-wrapper {
    display: block !important;
}