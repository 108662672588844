.navbar {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .navbar-left,
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .menu-button svg,
  .menu-button-mobile svg {
    width: 24px;
    height: 24px;
    fill: #333;
    cursor: pointer;
  }
  
  .search {
    position: relative;
    margin-left: 15px;
  }
  
  .search input {
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 14px;
    outline: none;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #888;
    cursor: pointer;
  }
  
  .navbar-logo .logo,
  .navbar-logo .logo-mobile {
    display: inline-block;
    width: 100px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .header-icons .header-icon {
    margin-left: 15px;
    cursor: pointer;
  }
  .logo-center{
    width: 300px;
  }
  .header-icons .count {
    background-color: red;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    padding: 2px 6px;
    position: absolute;
    top: -5px;
    right: -5px;
  }
  
  .user .name {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
  }
  
  .user img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .dropdown-menu {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .dropdown-item {
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f7f7f7;
  }
  
  


  .dropdown-clientes {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
    text-align: center;
    padding: 15px;
    border-bottom: none !important;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-clientes i {
    font-size: 35px;
  }
  
  .dropdown-clientes:hover {
    background-color: #f1f1f1;
  }
  
  /* Estilo específico para o título do Dropdown */
  .dropdown-clientes .dropdown-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333 !important;
    border: none;
    background: none;
    padding: 0;
  }
  
  .dropdown-clientes .dropdown-toggle:after {
    display: none !important; /* Oculta a seta */
    content: ''; /* Remove qualquer conteúdo */
  }

  .dropdown-menu {
    min-width: 280px !important; /* Ajuste do tamanho mínimo */
    text-align: left; /* Alinha os itens do dropdown */
    z-index: 1050 !important;
  }
  .dropdown-item{
    color: #000 !important;
  }
  .dropdown-menu .dropdown-item {
    font-size: 18px !important;
    padding: 10px;
  }
  
  .dropdown-menu .dropdown-item i {
    font-size: 25px !important;
  }
  
  .FontAwesomeIcon {
    font-size: 25px !important;
    padding: 7px
  }
  
  
  
  .dropdown-menu .dropdown-item:hover {
    background-color: #f5f5f5;
    color: #2A3072 !important;
    border: none !important;
  }
  
  .btn-link:hover{
    color: #2A3072 !important;
  }
  .btn-link:active{
    color: #2A3072 !important;
  }
  