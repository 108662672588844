/* Garante que o rodapé estará fixo no final da tela */
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #343a40;
    color: white;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  