@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700&display=swap');
body {
  font-family: 'Nunito', sans-serif !important;
}
h1, h2, h3, p, a {
  font-family: 'Nunito', sans-serif !important;
}
.nanito {
  font-family: 'Nunito', sans-serif !important;
}
.css-dip3t8 {
    position: relative;
    height: 100%;
    overflow-y: visible !important; 
    overflow-x: visible !important;
    z-index: 3;
    background-color: white;
}
.css-15x041p >.ps-menu-button:hover {
    background-color: white;
    color: black !important;
    z-index: 0;
}
.css-1gpbj98 >.ps-menu-button:hover {
    background-color: white;
    color: black !important;
    z-index: 0;
}
.css-1tqrhto >.ps-menu-button:hover {
    background-color: white !important;
    color: black !important;
}

.css-61tg8l >.ps-menu-button:hover {
    background-color: white !important;
    color: black !important;
}
.css-15x041p >.ps-menu-button, .css-1tqrhto >.ps-menu-button, .css-61tg8l >.ps-menu-button {
    margin-left: 5%
}
.css-1gpbj98 >.ps-menu-button, .css-1tqrhto >.ps-menu-button, .css-61tg8l >.ps-menu-button {
    margin-left: 5%
}
/*Cliente*/
