@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700&display=swap');
body {
  font-family: 'Nunito', sans-serif !important;
}
h1, h2, h3, p {
  font-family: 'Nunito', sans-serif !important;
}
.nanito {
  font-family: 'Nunito', sans-serif !important;
  font-optical-sizing: auto;
}

.menu-avatar{
  color: #000 !important;
}

.coluna-h-navbar {
  position: fixed; /* Já está fixo no topo */
  top: 0;
  left: 0;
  width: 100vw; /* Garante que use a largura total da viewport */
  z-index: 1000;
  -webkit-box-shadow: -11px 1px 18px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -11px 1px 18px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -11px 1px 18px -1px rgba(0, 0, 0, 0.75);
  background-color: #2a3072;
  color: #fff;

  margin-left: 0; /* Remove qualquer margem herdada */
  margin-right: 0;
  padding-left: 0; /* Evita qualquer padding indesejado */
  padding-right: 0;
}
.menu-text {
  background-color: #30367e;
  margin: 0 !important;
  margin-left: 5% !important;
}
.coluna-dados {
  background-color: #f8f8f8;
  padding-right: 80px;
  width: 87% !important;
}
@media (max-width: 768px) {
  .coluna-dados {
    width: 100% !important;
  }
  .coluna-menu-novo{
    display: none;
  }
  .nome-role {
    display: none !important;
  }
  .table-responsive {
    max-width: 300px !important;
  }
  .logo-center{
    width: 50% !important;
  }
 .coluna-avatar{
  margin-right: 18px;
 }
  .sumir-mobile {
    display: none !important;
  }

  .offcanvas{
    width: 70% !important;
  }

  .main-menu {
    z-index: 1046 !important;
  }

}

.botao-menu-mobile{
  color: #1b1b1b !important;
}

@media (min-width: 769px) { 

  .sumir-desktop {
    display: none !important;
  }
  
  
}



#root > div > div:nth-child(2){
  height: 100vh;
}
.coluna-navbar {
  background-color: #fff !important;
  padding: 0px !important;
  min-height: 900px;
  width: 100%;
}
.coluna-menu-novo {
  
  width: 13% !important;
}
.coluna-navbar-end {
  background-color: #fff !important;
}
.coluna-avatar {
  text-align: right; 
  margin-top: 2%; 
  display: flex; 
  flex-direction: row;
  justify-content: flex-end; 
  align-items: center; 
}

.navbar a {
  text-decoration: none;
  font-size: 20px;
  color: white;
  margin-left: 5%;
}

.navbar ul {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.navbar li {
  list-style: none;
  padding-top: 5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  width: 100%;

}

.navbar li:hover  {
  background-color: #30367e;
}

.logo-navbar-clienteprime{
  width: 3%;
  margin: 1% 0 1% 2%;
}

input {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.css-1wvake5 {
  width: 100% !important;
    min-width: 100% !important;
}
.css-17frj2h >.ps-menu-button:hover, .css-6njk15 >.ps-menu-button:hover, .css-1tqrhto >.ps-menu-button:hover {
  background-color: #fff !important;
}
.css-1tqrhto {
  color: #30367e;
  background-color: #fff;
}
.css-61tg8l {
  color: #30367e;
  background-color: #fff;
}

.css-5tlzss >.ps-menu-button:hover {
  background-color: #fff !important;
}
.css-1baojia >.ps-menu-button:hover {
  background-color: #fff !important;
}

  @media screen and (max-width: 576px) {
    #justify-tab-example-tabpane-Vendedor {
      overflow: scroll !important;
    }
    
  }

  .menu-textp{
    color: #888888 !important;
    font-family: 'Nunito', sans-serif !important;

  }
  .menu-textp span{
    font-family: 'Nunito', sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    
  }

  .DashboardCards-principal {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }

  .cardPontosDistribuidos{
    border-radius: 8px !important;
  }


  .loader {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    color: #2A3072;
    animation: smokeOut 1s ease-in-out infinite alternate;
    text-shadow: 0 0 1px #151841;
}

.loader:before {
    content: "Carregando";
}

@keyframes smokeOut {
    100% {
        opacity: 0.08;
        filter: blur(5px);
        letter-spacing: 4px;
    }
}


@media (max-width: 768px) {
  
}


.custom-switch-lg {
  display: flex !important;
  align-items: center !important; /* Centraliza verticalmente */
  gap: 8px !important; /* Espaçamento entre switch e label */
  font-size: 16px !important;
  margin-right: 20px !important;
}

.custom-switch-lg .form-check-input {

  width: 3rem !important;  /* Largura maior */
  height: 1.5rem; /* Altura maior */
}

.custom-switch-color .form-check-input:checked {
  background-color: #2A3072;
}

.botao-clique {
  cursor: pointer;
}