.container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #00000083;
}

.login-desktop-div {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}
.login-desktop-prime-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100vh;
    background-color: #30367E !important;
    justify-content: center;
}

.login-desktop-prime-2 > div:nth-child(1){
    height: 30%;
}

.login-desktop-prime {
    background-image: url('../../assets/sejaprime.jpg');
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    
}

.login-desktop-prime>h2 {
    margin-top: 34%;
    margin-left: 60%;
    font-size: medium;
    color: white;
}

.logo-login-img {
    margin-top: 10% !important;
    width: 100%;
    height: 100%;
}

.logo-login-img>img {
    -webkit-box-shadow: 15px 13px 20px 7px #000000;
    box-shadow: 15px 13px 20px 7px #000000;
}

.card-form-login {
    width: 30%;
    color: white;

}

#nickName, #password {
    background-color: transparent !important;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: white !important;
    color: white;

}

.card-form-login > div > form > button {
    background-color: #F15005 !important ;
    width: 100%;
}

.logo-login-cp-header {
    height: 50px;
    width: 100%;
    background-color: #F15005;
}

.logo-login-cp-img {
    height: 100%;

}

.button-prime-orange{
    background-color: #f15005 !important;
    color: white !important;
    width: 100%;
    margin-bottom:3%;
    transform: all 1s;
}

.button-prime-orange-login {
    color: white !important;
    width: 100%;
    background: linear-gradient(90deg,  black, #f15005) !important;
    margin-bottom: 3%;
    position: relative; /* Adicione esta linha para garantir que o ::before seja posicionado corretamente */
    overflow: hidden;   /* Adicione esta linha para garantir que o ::before não transborde */
    z-index: 1;         /* Certifique-se de que o botão tenha um índice de empilhamento maior que o ::before */
    transition: all 1s !important;
}

.button-prime-orange-login::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #f15005 0%, #ff4500 50%, #ff6347 100%);;
    z-index: -1;
    transform: translateX(100%);
    transition: transform 1s;
}

.button-prime-orange-login:hover::before {
    transform: translateX(0);
}


#root > div > div > div.login-desktop-div > div.login-desktop-prime-2 > div.mt-5.card-form-login > div > a{
    text-decoration: none !important;
    color: white;
}


@media screen and (max-width: 760px) {
    .login-desktop-prime {
        display: none;
    }
    .login-desktop-prime-2 {
        width: 100vw;
        height: 100vh;
    }
    .card-form-login {
        width: 80vw;

    }
}
