.main-menu {
    background-color: #fff !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    width: 13%;
    height: 100vh !important;
    overflow-y: auto !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    overflow: visible !important; 
    z-index: 1000 !important; 
  }
  @media (max-width: 768px) {
    .main-menu {
      display: none;
    }
  }

  .menu-principal {
    
  }
  
  .scroll {
    padding: 20px !important;
  }
  
  .list-unstyled {
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .list-unstyled li {
    
  }
  
  .list-unstyled li a {
    display: flex !important;
    align-items: center !important;
    text-decoration: none !important;
    color: #333 !important;
    font-size: 14px !important;
    font-weight: 500v;
    transition: color 0.3s ease !important;
  }
  
  .list-unstyled li a:hover {
    color: #2A3072 !important
  }
  
  .list-unstyled li.active a {
    color: #2A3072 !important;
    font-weight: 600 !important;
  }


  
  .list-unstyled li a i {
    margin-right: 10px !important;
    font-size: 35px !important;
  }
  

  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit; /* Preserva a cor do link padrão */
    text-align: center;
  }
  
  .menu-item i { /* Ajusta o tamanho do ícone */
    margin-bottom: 8px; /* Espaço entre ícone e texto */
  }
  
  .menu-item span {
    font-size: 14px; /* Ajuste de tamanho do texto */
  }


  .menu-container {
    display: flex;
    position: relative;
  }
  
  .main-menu {
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
  }
  
  .sub-menu {
    flex-grow: 1;
    padding: 15px;
    background-color: #ffffff;
  }
  
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 15px;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(243, 243, 243);
  }
  
  .menu-item:hover {
    background-color: #f1f1f1;
  }

  .submenu {
    list-style: none;
  }


  .submenu-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .submenu-list li {
    margin: 10px 0;
  }
  
  .submenu-list li a {
    text-decoration: none;
    color: #333;
    padding: 5px 10px;
    display: block;
    border-radius: 4px;
  }
  
  .submenu-list li a:hover {
    background-color: #f5f5f5;
  }

  .submenu-icon li a i {
    font-size: 20px !important;
  }

  /* Customização do Scroll */
.main-menu::-webkit-scrollbar,
.sub-menu::-webkit-scrollbar {
  width: 5px !important; /* Largura do scroll */
}

.main-menu::-webkit-scrollbar-track,
.sub-menu::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo do trilho */
  border-radius: 5px;
}

.main-menu::-webkit-scrollbar-thumb,
.sub-menu::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* Cor da barra de rolagem */
  border-radius: 5px;
}

.main-menu::-webkit-scrollbar-thumb:hover,
.sub-menu::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3; /* Cor ao passar o mouse */
}

/* Estilo para alinhar o DropdownButton ao restante do menu */
.dropdown-clientes {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  text-align: center;
  padding: 15px;
  border-bottom: 1px solid #f3f3f3;
  transition: background-color 0.3s ease;
}

.dropdown-clientes i {
  font-size: 35px;
}

.dropdown-clientes:hover {
  background-color: #f1f1f1;
}

/* Estilo específico para o título do Dropdown */
.dropdown-clientes .dropdown-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  border: none;
  background: none;
  padding: 0;
}

.dropdown-clientes .dropdown-toggle:after {
  display: none !important; /* Oculta a seta */
  content: ''; /* Remove qualquer conteúdo */
}

/* Estilo para os itens do Dropdown */
.dropdown-menu {
  min-width: 180px; /* Ajuste do tamanho mínimo */
  text-align: left; /* Alinha os itens do dropdown */
  z-index: 1050 !important;
}

.dropdown-menu .dropdown-item {
  font-size: 18px !important;
  padding: 10px;
}

.dropdown-menu .dropdown-item i {
  font-size: 25px !important;
}

.FontAwesomeIcon {
  font-size: 25px !important;
  padding: 7px
}



.dropdown-menu .dropdown-item:hover {
  background-color: #f5f5f5;
  color: #2A3072 !important;
}

.btn-link:hover{
  color: #2A3072 !important;
}
.btn-link:active{
  color: #2A3072 !important;
}

