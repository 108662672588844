.background-ponto-digital {
    background-image: url('../../assets/ponto1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    
}

.div-card-ponto-digital {
    justify-content: center;
    margin-top: 25vh;
    width: 50%;
    margin-inline: auto;
}

.div-card-ponto-digital>.card {
    text-align: center;
    background-color: rgba(48, 54, 126, 0.75) !important;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    
}
.div-card-ponto-digital > div > div.card-title.h5 > h1 {
    color: white;
}
.logo-ponto-digital > img{
    width: 15% !important;
    height: 15% !important;
}
.div-card-ponto-digital > div > div.card-body > form > button {
    width: 30%;
    height: 10%;
}