@import url('https://fonts.googleapis.com/css?family=Montserrat');



h3 {
	margin: 10px 0;
}

h6 {
	margin: 5px 0;
	text-transform: uppercase;
}

p {
	font-size: 14px;
	line-height: 21px;
}

.card-container {
	background-color: #2A3072;
	border-radius: 25px;
	box-shadow: 10px 10px 15px rgba(0,0,0,0.35);
	color: #B3B8CD;
	padding: 20px 20px 0;
	position: relative;
	
	width: 100%;
	text-align: center;
	margin-top: 20px;
	overflow: hidden;
}

.card-container .pro {
	color: #FFF;
	background-color: #257c38;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	padding: 3px 7px;
	position: absolute;
	top: 30px;
	left: 30px;
}

.card-container img {
	border: 1px solid #fff;
	border-radius: 50%;
	padding: 7px;
	height: 150px;
	width: 150px;
    margin-bottom: 10px;
}

button.primary {
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 3px;
	color: #2A3072;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	padding: 10px 25px;
}

button.primary.ghost {
	background-color: transparent;
	color: #fff;
}

.skills {
	background-color: #2A3072;
	text-align: left;
	padding: 15px;
	margin-top: 30px;
}

.skills ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.skills ul li {
	border: 1px solid #2A3072;
	border-radius: 2px;
	display: inline-block;
	font-size: 12px;
	margin: 0 7px 7px 0;
	padding: 7px;
}


.botao{
    margin: 10px;
    width: 100%;
}

.buttons{
    padding-right: 10px;
}