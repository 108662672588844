/* ProgressCard.css */
.progress-card {
    background-color: #2A3072 !important;
    border: none;
    border-radius: 8px;
    padding: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.progress-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progress-card-icon {
    font-size: 70px;
    margin-right: 10px;
    color: white;
}

.progress-card-lead {
    font-size: 25px;
    margin: 0;
    color: white;
}

.progress-card-text-small {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
}

.progress-card-circle {
    width: 80px;
    height: 80px;
    position: relative;
}

.progress-card-text {
    font-size: 14px;
    font-weight: bold;
    color: white;
}
