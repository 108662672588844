/* DashboardCards.css */
.icon-cards-row {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.dashboard-numbers {
    width: 100%;
}

.glide__slides {
    display: flex;
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.glide__slide {
    flex: 1;
}

.card {
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-body {
    padding: 20px;
}

.card-body i {
    font-size: 36px;
    margin-bottom: 10px;
    color: #2A3072;
}

.card-text {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 5px;
}

.lead {
    margin-top: 12px;
    font-size: 24px;
    font-weight: bold;
    color: #343a40;
}


