@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700&display=swap');
body {
  font-family: 'Nunito', sans-serif !important;
}
h1, h2, h3, p {
  font-family: 'Nunito', sans-serif !important;
}
.nanito {
  font-family: 'Nunito', sans-serif !important;
}

.container-dashboard {
    background-color: #f8f8f8 !important;
}
.dashboard-div-title {
    display: flex;
    margin-top: 1%;
    padding: 1%;
    position: absolute;
    top: 0;
}

.dashboard-div-title h3 {
    margin-left: 1%;
    color:white;
    font-weight: bold;
    font-size: 2em;

}

.dashboard-buttons-rows {
    margin-left: 1%;
    padding: 2%;
}

.dashboard-card {
    border-radius: 20px !important;
    margin: 2%;
    cursor: pointer;
    border-width: 0px !important;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.50);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.50);
    box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.50);
    transition: all 1s;
    background-color: #fff !important;
    padding: 10%;
    color: #797979 !important;
}

.dashboard-card:hover {
    transform: scale(1.1);
}

.dashboard-card .card-body {
    padding: 0px !important;
}

.dashboard-card button {
    border-radius: 0;
}

.dashboard-card-icon {
    text-align: center;
    position: relative;
    padding: 2%;
    width: 150px;
    height: 80px;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.dashboard-card-icon > img {
    width: 50% !important;
    background-color: #888888 !important;
}




.dashboard-button-novousuario {
    width: 100%;
    color: #30367E !important;
    background-color: #fff !important;
    border-color: transparent !important;
    font-size: 18px !important;
    border-radius: 20px !important;
    font-weight: bold;
    color: rgba(42, 48, 114, 0.9) !important;
    /* font-weight: bold; */
}

.bg-red {
    background-color: red;
}

.bg-blue {
    background-color: blue;
}

.bg-green {
    background-color: green !important;
}

.bg-orange {
    background-color: #f15005;
}

.bg-purple {
    background-color: purple
}

.bg-red {
    background-color: red;
}

.bg-gray {
    background-color: gray;
}

.dashboard-dadosplataforma-title {
    font-size: 80%;
    padding: 5%;
}
.dashboard-dadosplataforma-title > .col > .row > h3 {
    color: black;
    font-size: 150%;
    text-align: center;
}

.dashboard-dadosplataforma-title .col {
    padding: 1%;
}

.dashboard-dadosplataforma-cards {
    border-radius: 20px !important;
    padding: 2%;
    height: 65px !important;
    color: white !important;
    -webkit-box-shadow: 10px 10px 13px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 13px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 13px -10px rgba(0, 0, 0, 0.75);
    background-color: #30367E !important;
}

.dashboard-dadosplataforma-cards-row {
    display: flex;

}

.dashboard-dadosplataforma-cards-row-percent {
    color: rgb(103, 255, 103);
    margin-left: 3%;
}

.dashboard-dadosplataforma-cards-row-icon {
    position: absolute;
    color: white;
    right: 25px;
    top: 25px;
}

.dashboard-graficos {
    margin-left: 2% !important;
    width: 100%;
}
.dashboard-card-model {
    background-color: white;
    padding: 1%;
    border-radius: 20px !important;
    border: initial;
    -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
    -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
    box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
}
.dashboard-dadosplataforma-cards > p, .dashboard-dadosplataforma-cards-row > h5 {
    font-size: 15px;
    margin-left: 5%;
}
#root > div > div:nth-child(2) > div.coluna-dados.col-9 > div > div.dashboard-graficos.row > div.col-5 {
    width: 39.666667% !important;
    height: 100% !important;
}
.model-vh {
    height: 700px !important;
}

.icone-grafico{   
    color: #888888 !important;
    margin-bottom: 8px;
    width: 80px !important;
    margin: auto;
}
.titulo-grafico{
    color: rgba(42, 48, 114, 0.9) !important;
    font-weight: bold;
    font-size: 17px !important;
    margin-top: 5px;
    font-family: 'Nunito', sans-serif !important;
}

.div-grafico{
    text-align: center !important;
}
.numero-grafico{
    color: #888888 !important;
    
}

.porcentagem{
    font-size: 10px;
}